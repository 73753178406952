.css3panel-mainimage-s1 {
    background-image: url("css3panels-alt-01.jpg");
}

.css3panel-mainimage-s2 {
    background-image: url("css3panels-alt-02.jpg");
}

.css3panel-mainimage-s3 {
    background-image: url("css3panels-alt-03.jpg");
}

.css3panel-mainimage-s4 {
    background-image: url("css3panels-alt-04.jpg");
}

.css3panel-mainimage-s5 {
    background-image: url("css3panels-alt-05.jpg");
}