.pravidla li {
    margin: 10px 0;
    list-style-type: decimal;
}

.pravidlaUl li {
    margin: 10px 10px 10px 40px;
    list-style-type: square;

}

.accordionContent {
    background:#fff;
    padding: 25px;
}

.accordion-button {
    /* border: 1px solid red; */
    position: relative;
    overflow: hidden;
    border: 2px solid #04AA6D; /* Green */  
    width: 100%;
    padding: 20px;
}

.accordion-button:not(.collapsed){
    color: #a7b1c2;
    /* background-color: #293846; */
    background-color: white;
    color: black;
}

.accordion-button:hover {
    color: #a7b1c2;
    /* background-color: #293846; */
}

.panel-heading{    
    padding: 0px;
}
.accordion {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.accordion-item {
    border-bottom: 1px solid #e0e0e0;
}

.accordion-header {
    background-color: #f5f5f5;
    padding: 16px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.accordion-header:hover {
    background-color: #e0e0e0;
}

.accordion-content {
    padding: 16px;
    display: none;
    background-color: #fafafa;
    font-size: 16px;
    line-height: 1.6;
}

.accordion-item.active .accordion-content {
    display: block;
}


  .accordion-body {
    padding: 0 30px;
    background-color: white;
  }



  .box-download {
    background-color: #f6f6f6;
    padding: .9375rem;
    display: block;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row;
    flex-flow: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #3b355b;
    border-radius: 8px;
    margin-bottom: .9375rem;
}
.box-download .button {
    margin-bottom: 0;
}

.button.download, .menu .button.download {
    color: #483c94;
    background-position: center right 5px;
    padding-right: 2.5rem;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 12.1117V17.3021C22 19.5362 20.1202 21.3421 17.8258 21.3421H6.17415C3.87975 21.3421 2 19.5228 2 17.3021V12.1117H3.10574V17.3021C3.10574 18.9342 4.48791 20.2719 6.17415 20.2719H17.8258C19.5121 20.2719 20.8943 18.9342 20.8943 17.3021V12.1117H22ZM17.3144 12.7271L16.5681 11.9378L12.5598 15.4694V4.5H11.454V15.4828L7.43193 11.9378L6.68556 12.7271L12.0069 17.4092L17.3144 12.7271Z' fill='%23483C94'/%3E%3C/svg%3E%0A");
}
.button.plain, .menu .button.plain {
    font-weight: 600;
    font-family: 'Open Sans',sans-serif;
    background-color: transparent;
    padding: 0;
}

.button, .menu .button {
    border-radius: .5rem;
    font-size: 1rem;
    font-weight: 500;
    padding: 1.25rem 1.5rem;
    font-family: 'Open Sans',sans-serif;
}
