.zlavy {
    font-size: 70%;
}
.zlavy li{ 
    padding: 10px 0 10px 0px;
}

.ceny {
    text-align: left;
    font-size: 70%;
    padding-left: 15px
    
}
.ceny li{ 
    padding: 10px 0px 10px 0px;
    list-style: square;
}